import { Link } from "react-router-dom";

// material-ui
import ButtonBase from "@mui/material/ButtonBase";

// project imports
import Logo from "src/components/Logo";
import config from "src/config";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
  <ButtonBase disableRipple component={Link} to={config.defaultPath}>
    <Logo />
  </ButtonBase>
);

export default LogoSection;
