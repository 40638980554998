// material-ui
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Theme } from "@mui/material/styles";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@mui/styles";

// third-party
import { BrowserView, MobileView } from "react-device-detect";
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import { useCallback } from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import { isHostAvailable } from "src/App";
import useAuth from "src/hooks/useAuth";
import { CurrentAppUserMenu } from "src/layout/MainLayout/Header/CurrentAppUserMenu";
import { drawerWidth } from "src/stores/constant";
import { BXApp } from "src/types/BXAppType";
import { pagesToSidebarItems } from "src/utils/transformers";
import { useBXContext } from "../../../BXEngine/BXContext";
import { AdministrationSwitcher } from "../Header/AdministrationSwitcher";
import LogoSection from "../LogoSection";
import MenuList from "./MenuList";
// style constant
const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    borderRight: "none",
    [theme.breakpoints.up("md")]: {
      top: "85px",
    },
    borderRadius: 10,
  },
  ScrollHeight: {
    height: "calc(100vh - 82px)",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 56px)",
    },
  },
  boxContainer: {
    display: "flex",
    padding: "16px",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

// ==============================|| SIDEBAR DRAWER ||============================== //

export interface SidebarProps {
  drawerOpen?: boolean;
  drawerToggle?: () => void;
  window?: Window;
}

const Sidebar = ({ drawerOpen, drawerToggle, window }: SidebarProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const { isSuperAdmin } = useAuth();

  const { appDescriptor, currentApp, isAdministrationMode, loadingApps } = useBXContext();

  const application: BXApp | null = appDescriptor?.find(app => app.name === currentApp?.name) || null;

  const menuItems = useCallback((isAdministrationMode?: boolean) => pagesToSidebarItems(application, isAdministrationMode), [application]);

  const drawer = (
    <>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <div className={classes.boxContainer}>
          <LogoSection />
        </div>
      </Box>
      <BrowserView>
        <PerfectScrollbar component='div' className={classes.ScrollHeight}>
          <div style={{ marginTop: 10 }}>{isSuperAdmin() && <AdministrationSwitcher />}</div>
          {(localStorage.getItem("admin-login") ||
            isHostAvailable ||
            (currentApp?.appConfig?.withProfiles && currentApp?.appConfig?.isSingleSignOn)) && <CurrentAppUserMenu />}

          <Box padding={2} paddingRight={0}>
            {menuItems().length === 0 && !isAdministrationMode && !loadingApps && (
              <div>
                {!appDescriptor?.length
                  ? "You are not connected to any application, contact the administrator"
                  : "No pages created in this app yet"}{" "}
              </div>
            )}
            <MenuList menuItems={menuItems(isAdministrationMode)} />
          </Box>
        </PerfectScrollbar>
      </BrowserView>
      <MobileView>
        <Box sx={{ px: 2 }}>
          <MenuList menuItems={menuItems(isAdministrationMode)} />
        </Box>
      </MobileView>
    </>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <nav className={classes.drawer} aria-label='mailbox folders'>
      <Drawer
        container={container}
        variant={matchUpMd ? "persistent" : "temporary"}
        anchor='left'
        open={drawerOpen}
        onClose={drawerToggle}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{ keepMounted: true }}
        color='inherit'
      >
        {drawer}
      </Drawer>
    </nav>
  );
};

export default Sidebar;
