import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import { FC } from "react";
import AnimateButton from "src/components/extended/AnimateButton";
import { useBXContext } from "../../../../BXEngine/BXContext";

export const CurrentAppUserMenu: FC = () => {
  const {
    isSwitchingMode,
    currentApp,
    isAdministrationMode,
    setIsSwitchingMode,
    getAuth,
    appProfiles,
    currentProfileId,
    setCurrentProfileId,
    setLoadingApps,
  } = useBXContext();

  const { palette } = useTheme();

  if (!currentApp || isAdministrationMode) return <></>;

  const { token } = getAuth(currentApp!.id) || {};
  if (!token) return <></>;

  return (
    !isAdministrationMode && (
      <div style={{ paddingRight: 16, paddingLeft: 16, paddingBottom: 0, paddingTop: 8 }}>
        {!isSwitchingMode ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {appProfiles?.[currentApp?.id]?.map((profile: any) => {
                  const { user } = getAuth(currentApp?.id, profile?.id, profile?.signInWithGoogle);

                  return (
                    <Tooltip key={profile?.id} title={profile?.profileName || user?.name}>
                      <Grid
                        item
                        onClick={() => {
                          setLoadingApps(true);
                          localStorage.setItem(currentApp?.id + "-profileId", profile?.id);
                          setCurrentProfileId(profile?.id);
                          setTimeout(() => {
                            setLoadingApps(false);
                          }, 0);
                        }}
                      >
                        <AnimateButton>
                          <Avatar
                            style={{
                              cursor: "pointer",
                              border: currentProfileId == profile?.id ? `2px solid ${palette.primary.main}` : "2px solid",
                              boxSizing: "content-box",
                            }}
                            src={user?.profilePhoto}
                          />
                        </AnimateButton>
                      </Grid>
                    </Tooltip>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth variant='contained' onClick={() => setIsSwitchingMode(true)}>
                Manage Profiles
              </Button>
            </Grid>
          </Grid>
        ) : (
          <LoadingButton
            fullWidth
            onClick={() => {
              setIsSwitchingMode(false);
            }}
            color='error'
          >
            Return to {currentApp?.name || "App"}
          </LoadingButton>
        )}
      </div>
    )
  );
};
