import { Theme, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import {
  IconCopy,
  IconExchange,
  IconEye,
  IconEyeOff,
  IconFileExport,
  IconLock,
  IconPencil,
  IconTrash,
  IconUser,
  IconX,
} from "@tabler/icons";
import { useState } from "react";
import { BXIcon } from "src/components/BXUI/Icon";
import { useDnD } from "src/hooks/useDnD";
import MoveViewsModal from "./MoveViewsModal";

// style constant
const useStyles = makeStyles((theme: Theme) => ({
  rowContainer: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    "&:hover $dotsIconStyle": {
      display: "block",
    },
  },
  dotsIconStyle: {
    display: "none",
  },
  iconStyle: {
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

const BuilderRow = ({
  item,
  index,
  headerName,
  isHidden,
  withEye,
  moveApp,
  updateApp,
  withMove,
  type,
  setSelectedRow,
  selectedRow,
  onConfigClick,
  onUserClick,
  onTrashClick,
  onEditClick,
  onMoveClick,
  onDuplicateClick,
  onExportClick,
}: any) => {
  const classes = useStyles();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { palette } = useTheme();

  const { ref, opacity, handlerId } = useDnD({
    id: item?.id,
    index,
    name: headerName,
    moveCard: moveApp,
    updateCard: (dragIndex, hoverIndex) => updateApp(dragIndex, hoverIndex),
  });

  const [anchorEl, setAnchorEl] = useState<any>(null);

  const handleClick = (event: any) => {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMoveClick = () => {
    setIsModalOpen(true);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      {withMove && <MoveViewsModal open={isModalOpen} setOpen={setIsModalOpen} item={item} viewType={type} handleMoveClick={onMoveClick} />}
      <div ref={ref} style={{ opacity }} data-handler-id={handlerId}>
        <Grid
          xs
          container
          bgcolor={selectedRow?.id == item?.id ? palette.primary.light : ""}
          marginRight={2}
          borderRadius={"10px"}
          padding={1}
          flexWrap='nowrap'
          className={classes.rowContainer}
          onClick={e => {
            setSelectedRow(item);
          }}
        >
          <Grid item>
            <BXIcon icon={item?.image} height={30} width={30} fallBack={item?.fallBack || "Folder"} />
          </Grid>
          <Grid item xs paddingLeft={2}>
            <Grid item>
              <Typography
                sx={{
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                }}
                flex={1}
                fontSize={"14px"}
                marginBottom={0.5}
              >
                {item?.title}
              </Typography>
            </Grid>
            {item?.subTitle && (
              <Grid item>
                <Typography
                  sx={{
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                  }}
                  fontSize={"12px"}
                >
                  {item?.subTitle}
                </Typography>
              </Grid>
            )}
          </Grid>
          {withEye && <Grid item>{isHidden ? <IconEyeOff color='gray' /> : <IconEye />}</Grid>}
          <Grid onClick={handleClick} item className={classes.dotsIconStyle} ml={1}>
            <svg width='30' height='30' viewBox='-1 -1 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M15 9.4375V9.5625' stroke={palette.text.primary} stroke-width='3' stroke-linecap='round' stroke-linejoin='round' />
              <path d='M15 15.5625V15.6875' stroke={palette.text.primary} stroke-width='3' stroke-linecap='round' stroke-linejoin='round' />
              <path d='M15 21.6875V21.8125' stroke={palette.text.primary} stroke-width='3' stroke-linecap='round' stroke-linejoin='round' />
              <path
                d='M10 1H20V-1H10V1ZM29 10V20H31V10H29ZM20 29H10V31H20V29ZM1 20V10H-1V20H1ZM10 29C5.02944 29 1 24.9706 1 20H-1C-1 26.0751 3.92487 31 10 31V29ZM29 20C29 24.9706 24.9706 29 20 29V31C26.0751 31 31 26.0751 31 20H29ZM20 1C24.9706 1 29 5.02944 29 10H31C31 3.92487 26.0751 -1 20 -1V1ZM10 -1C3.92487 -1 -1 3.92487 -1 10H1C1 5.02944 5.02944 1 10 1V-1Z'
                fill={palette.primary.main}
              />
            </svg>
          </Grid>
        </Grid>
        <Popover
          id={id}
          componentsProps={{
            backdrop: {
              style: {
                backgroundColor: "transparent",
              },
            },
          }}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box
            onClick={handleClose}
            boxShadow={`2px 4px 12px 0px ${palette.primary.main}, -4px -4px 12px 0px ${palette.primary.main}`}
            border={`1px solid ${palette.primary.main}`}
            borderRadius={"10px"}
          >
            <Grid container spacing={1.5} padding={1}>
              {[
                (props: any) => <IconX {...props} />,
                () => <Divider orientation='vertical' />,
                onEditClick ? (props: any) => <IconPencil {...props} onClick={onEditClick} /> : null,
                onConfigClick ? (props: any) => <IconLock {...props} onClick={onConfigClick} /> : null,
                onUserClick ? (props: any) => <IconUser {...props} onClick={onUserClick} /> : null,
                onDuplicateClick ? (props: any) => <IconCopy {...props} onClick={onDuplicateClick} /> : null,
                onExportClick ? (props: any) => <IconFileExport {...props} onClick={onExportClick} /> : null,
                withMove ? (props: any) => <IconExchange {...props} onClick={handleMoveClick} /> : null,
                onTrashClick ? (props: any) => <IconTrash {...props} onClick={onTrashClick} /> : null,
              ].map((Icon: any, index) =>
                Icon ? (
                  <Grid key={index} item>
                    <Icon className={classes.iconStyle} />
                  </Grid>
                ) : null
              )}
            </Grid>
          </Box>
        </Popover>
      </div>
    </>
  );
};
export default BuilderRow;
