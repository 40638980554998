import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import BuilderRow from "./BuilderRow";

type Props = {
  id?: any;
  withDivider?: boolean;
  rows?: any;
  selectedData?: any;
  headerName: string;
  modal?: any;
  type?: string;
  withMove?: boolean;
  moveApp: (dragIndex: any, hoverIndex: number) => void;
  updateApp: (dragIndex: any, hoverIndex: number) => void;
  onConfigClick?: (prop?: any) => void;
  onUserClick?: (prop?: any) => void;
  onTrashClick?: (prop?: any) => void;
  onEditClick?: (prop?: any) => void;
  onMoveClick?: any;
  onDuplicateClick?: (prop?: any) => void;
  onExportClick?: (prop?: any) => void;
  onSelect?: (prop?: any) => void;
};

const BuilderColumn = (props: Props) => {
  const {
    id,
    rows,
    selectedData,
    headerName,
    modal,
    type,
    withMove,
    onSelect,
    updateApp,
    moveApp,
    onConfigClick,
    onEditClick,
    onTrashClick,
    onUserClick,
    onMoveClick,
    onDuplicateClick,
    onExportClick,
    withDivider = true,
  } = props;

  const [selectedRow, setSelectedRow] = useState<any>();

  useEffect(() => {
    setSelectedRow(selectedData);
  }, [selectedData]);

  const BuilderColumnHeader = () => {
    return (
      <>
        <Grid container alignItems='center'>
          <Grid item xs>
            <Typography fontSize={16} fontWeight={600}>
              {headerName}
            </Typography>
          </Grid>
          <Grid item>{modal}</Grid>
        </Grid>
        <Divider style={{ marginTop: 10 }} />
      </>
    );
  };

  return (
    <Grid item xs={3}>
      <BuilderColumnHeader />
      <Grid container marginY={2}>
        <Grid xs flexDirection={"column"} height='90vh' marginRight={withDivider ? 1 : 0} style={{ overflowY: "auto", overflowX: "auto" }}>
          {!!rows?.length &&
            rows.map((item: any, index: any) => (
              <BuilderRow
                key={item?.id}
                item={item}
                index={index}
                withMove={withMove}
                withEye={type == "view"}
                type={type}
                onMoveClick={onMoveClick}
                isHidden={item?.info?.visibility == "Hidden"}
                selectedRow={selectedRow}
                setSelectedRow={(_item: any) => {
                  onSelect?.(_item);
                  setSelectedRow(_item);
                }}
                updateApp={updateApp}
                moveApp={moveApp}
                headerName={`${headerName}-${id}`}
                onConfigClick={onConfigClick && (() => onConfigClick(item))}
                onUserClick={onUserClick && (() => onUserClick(item))}
                onTrashClick={onTrashClick && (() => onTrashClick(item))}
                onEditClick={onEditClick && (() => onEditClick(item))}
                onDuplicateClick={onDuplicateClick && (() => onDuplicateClick(item))}
                onExportClick={onExportClick && (() => onExportClick(item))}
              />
            ))}
        </Grid>
        {withDivider && (
          <Grid item>
            <Divider orientation='vertical' style={{ height: "95%", transform: "translateX(8px)" }} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default BuilderColumn;
