import { NavItemType, NavItemTypeObject } from "src/types";
import { BXApp } from "src/types/BXAppType";

/**
 * object tree of pages into nav items
 * @param app
 */
export const pagesToSidebarItems = (app: BXApp | null, isAdministrationMode?: boolean): NavItemType[] => {
  let groups: (NavItemTypeObject & NavItemType)[] = [];

  if (!isAdministrationMode) {
    if (app === null) return [];
    // we have a limitation in the original codebase, menu items must belong to a group
    const collections = app.templateConfig?.collections || [];

    for (let i = 0; i < collections.length; i++) {
      const collection = collections?.[i];
      const pages = collection.pages;
      const groupName = collection.slug;

      for (let j = 0; j < pages.length; j++) {
        const pageSlug = pages[j].slug;
        const foundGroup = groups.find(group => group.id === collection.id);
        const pageRoute: NavItemType = {
          title: pages[j].name,
          id: pages[j].name,
          type: "item",
          url: `/${app.slug}/${collection.slug}${pageSlug ? `/${pageSlug}` : null}`.replaceAll(/\/+/g, "/"),
          icon: pages[j].icon,
        };

        if (foundGroup) {
          foundGroup.children?.push(pageRoute);
        } else {
          groups.push({
            id: collection.id,
            type: "group",
            url: `/${groupName}`,
            title: collection.name,
            children: [pageRoute],
            icon: collection?.icon,
          });
        }
      }
    }
  } else {
    groups.push({
      id: "buildx/app",
      type: "group",
      url: "/",
      title: "",
      children: [
        {
          title: "Manage Users",
          id: "Manage Users",
          type: "item",
          url: "/buildx/users",
          icon: "Users",
        },
        {
          title: "Manage Apps",
          id: "Manage Apps",
          type: "item",
          url: "/buildx/app",
          icon: "Apps",
        },
        process.env.REACT_APP_WITH_OAS == "true"
          ? {
              title: "Manage OAS",
              id: "Manage OAS",
              type: "item",
              url: "/buildx/oas",
              icon: "FileCode",
            }
          : {},
        {
          title: "Manage ENV",
          id: "Manage ENV",
          type: "item",
          url: "/buildx/env",
          icon: "Key",
        },
        {
          title: "Manage Components",
          id: "Manage Components",
          type: "item",
          url: "/buildx/component",
          icon: "Components",
        },
      ],
      icon: "users",
    });
  }

  return groups;
};

export const pagesToProfileItems = (app: BXApp | null): NavItemType[] => {
  let groups: (NavItemTypeObject & NavItemType)[] = [];
  if (app === null) return [];
  // we have a limitation in the original codebase, menu items must belong to a group
  const collections = app.templateConfig?.collections || [];

  const appName = app.name;
  for (let i = 0; i < collections.length; i++) {
    const collection = collections?.[i];
    const pages = collection.pages;
    const groupName = collection.slug;

    for (let j = 0; j < pages.length; j++) {
      if (pages[j].inProfileMenu) continue;
      const pageName = pages[j].name;
      const foundGroup = groups.find(group => group.title === groupName);
      const pageRoute: NavItemType = {
        title: pageName,
        id: pages[j].name,
        type: "item",
        url: `/${appName}/${groupName}${pageName ? `/${pageName}` : null}`,
        icon: pages[i].icon,
      };

      if (foundGroup) {
        foundGroup.children?.push(pageRoute);
      } else {
        groups.push({
          id: groupName,
          type: "group",
          url: `/${groupName}`,
          title: groupName,
          children: [pageRoute],
        });
      }
    }
  }

  return groups;
};
