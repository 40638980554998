import { useNavigate } from "react-router-dom";

// project imports
import useAuth from "src/hooks/useAuth";
import config from "src/config";
import React, { FC, useEffect } from "react";

// ==============================|| GUEST GUARD ||============================== //

type GuestGuardProps = {
  children: React.ReactNode
}

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate(config.defaultPath, { replace: true });
    }
  }, [isLoggedIn, navigate]);

  return <>{children}</>;
};

export default GuestGuard;
