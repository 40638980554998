// material-ui
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonBase from "@mui/material/ButtonBase";
import Popover from "@mui/material/Popover";
import makeStyles from "@mui/styles/makeStyles";

// project imports
// import SearchSection from './SearchSection';
// import LocalizationSection from './LocalizationSection';
import MobileSection from "./MobileSection";
import ProfileSection from "./ProfileSection";
// import NotificationSection from './NotificationSection';
// assets
import { useTheme } from "@mui/material";
import { IconMenu2 } from "@tabler/icons";
import _ from "lodash";
import { FC, MouseEventHandler, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isHostAvailable } from "src/App";
import { BXIcon } from "src/components/BXUI/Icon";
import useAuth from "src/hooks/useAuth";
import { BXAppCollection } from "src/types/BXAppType";
import { useBXContext } from "../../../BXEngine/BXContext";

// style constant
const useStyles = makeStyles((theme: any) => ({
  grow: {
    flexGrow: 1,
  },
  headerAvatar: {
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    transition: "all .2s ease-in-out",
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
  boxContainer: {
    // width: "228px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
  },
}));

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

export interface HeaderProps {
  handleLeftDrawerToggle?: () => void;
}

const AppSelector: FC = () => {
  const {
    appDescriptor,
    currentApp: _currentApp,
    setCurrentApp,
    setIsAdministrationMode,
    isAdministrationMode,
    setIsSwitchingMode,
  } = useBXContext();

  const [anchorEl, setAnchorEl] = useState<any>(null);
  const { palette } = useTheme();

  const navigate = useNavigate();

  const handleClick: MouseEventHandler<HTMLButtonElement> = event => {
    if (!localStorage.getItem("admin-login") && !isHostAvailable) return;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  if (appDescriptor.length == 0 || isAdministrationMode) return <></>;

  const currentApp = appDescriptor.find(app => app?.id == _currentApp?.id);

  return (
    <>
      <Button
        aria-describedby={id}
        onClick={handleClick}
        variant={"contained"}
        sx={{
          backgroundColor: palette.background.default,
          borderRadius: 1,
          justifyContent: "space-between",
          color: palette.text.primary,
          fontSize: 16,
          fontWeight: 600,
        }}
        // disabled={!localStorage.getItem("admin-login") && !isHostAvailable}
        startIcon={<BXIcon icon={currentApp?.icon} fallBack={"Box"} />}
      >
        {(() => {
          if (_.isNil(currentApp) || _.isEmpty(currentApp)) {
            return "Go to app";
          }
          return currentApp?.name;
        })()}
      </Button>
      <Popover
        id={"bx-app-select"}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box paddingX={1} paddingY={2} maxHeight={300}>
          {appDescriptor.map(c => (
            <Box key={c.name} onClick={handleClose} mb={0.5}>
              <Button
                fullWidth
                style={{
                  color: palette.text.primary,
                  justifyContent: "flex-start",
                  border: `0.5px solid ${c.name === currentApp?.name ? palette.primary.main : "black"}`,
                  paddingLeft: 12,
                  paddingRight: 12,
                }}
                onClick={() => {
                  setIsSwitchingMode(false);
                  setCurrentApp(c);
                  setIsAdministrationMode(false);
                  const firstCollectionToHavePages = c.templateConfig?.collections?.find(
                    (collection: BXAppCollection) => collection.pages.length > 0
                  );
                  const collectionSlug = firstCollectionToHavePages?.slug;
                  const firstPageSlug = firstCollectionToHavePages?.pages?.[0]?.slug;
                  if (firstPageSlug) navigate({ pathname: `${c.slug}/${collectionSlug}/${firstPageSlug}`.replaceAll(/\/+/g, "/") });
                  else navigate({ pathname: `${c.slug}`.replaceAll(/\/+/g, "/") });
                }}
                startIcon={<BXIcon icon={c?.icon} fallBack={"Box"} size={32} />}
              >
                {c.name}
              </Button>
            </Box>
          ))}
        </Box>
      </Popover>
    </>
  );
};

const Header = ({ handleLeftDrawerToggle }: HeaderProps) => {
  const classes = useStyles();
  const { isSuperAdmin } = useAuth();

  return (
    <>
      {/* logo & toggler button */}
      <div className={classes.boxContainer}>
        {/* <Box component='span' sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}>
          <LogoSection />
        </Box>
        <div className={"vertical-divider"} /> */}

        {!_.isNil(handleLeftDrawerToggle) && !window.location.pathname.startsWith("/buildx/form-builder") && (
          <ButtonBase sx={{ borderRadius: "12px", overflow: "hidden" }}>
            <Avatar variant='rounded' className={classes.headerAvatar} onClick={handleLeftDrawerToggle} color='inherit'>
              <IconMenu2 stroke={1.5} size='1.3rem' />
            </Avatar>
          </ButtonBase>
        )}
        {!window.location.pathname.startsWith("/buildx/form-builder") && (
          <Box marginLeft={2}>
            <AppSelector />
          </Box>
        )}
      </div>

      {/* header search */}
      {/* <SearchSection theme="light" /> */}
      <div className={classes.grow} />

      <div className={classes.grow} />

      {/* live customization & localization */}
      {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <LocalizationSection />
            </Box> */}

      {/* notification & profile */}
      {/* <NotificationSection /> */}
      <ProfileSection />

      {/* mobile header */}
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        <MobileSection />
      </Box>
    </>
  );
};

export default Header;
