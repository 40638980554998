import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, Button, DialogActions, Grid, Typography } from "@mui/material";
import { IconLogin } from "@tabler/icons";
import _ from "lodash";
import { FC, useEffect } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { BXInput } from "src/components/BXUI/FormControls";
import * as yup from "yup";

type LoginFormProps = {
  id: string;
  onSave: SubmitHandler<FieldValues>;
  onCancel: Function;
  initial?: { username?: string; password?: string; profileName?: any };
  error?: string;
  disabled?: boolean;
  isEditForm: any;
  setIsEditForm: any;
  isAddProfile: any;
  setIsAddProfile: any;
};

const schema = yup
  .object({
    email: yup.string().required(),
    password: yup.string().required(),
  })
  .required();

export const LoginForm: FC<LoginFormProps> = ({
  onSave = _.noop,
  error,
  initial,
  id,
  isEditForm,
  setIsEditForm,
  isAddProfile,
  setIsAddProfile,
  disabled = false,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm<FieldValues>({ reValidateMode: "onChange", resolver: yupResolver(schema) });

  useEffect(() => {
    const { username, password, profileName } = initial || {};
    if (initial?.username && initial?.password) {
      setValue("email", username);
      setValue("password", password);
      setValue("profileName", profileName);
    }
  }, [initial?.username, initial?.password]);

  return (
    <Box component='form' noValidate autoComplete='off'>
      {error && (
        <Typography marginY={2} color={"red"} marginBottom={2}>
          {error}
        </Typography>
      )}
      <Grid container spacing={3} marginTop={2}>
        {isEditForm && (
          <Grid item xs={12}>
            <BXInput variant='outlined' required label={"profileName"} control={control} name={"profileName"} error={errors?.profileName} />
          </Grid>
        )}
        <Grid item xs={12}>
          <BXInput
            variant='outlined'
            required
            type={"email"}
            label={"Email"}
            id={id + "-bx-app-login-email-input"}
            control={control}
            name={"email"}
            error={errors?.email}
          />
        </Grid>

        <Grid item xs={12}>
          <BXInput
            variant='outlined'
            required
            label={"Password"}
            type={"password"}
            id={id + "-bx-app-login-password-input"}
            control={control}
            name={"password"}
            error={errors?.password}
          />
        </Grid>

        <Grid item xs={12}>
          <DialogActions style={{ padding: 0, marginTop: 16 }}>
            {(isEditForm || isAddProfile) && (
              <Box mr={2}>
                <Button
                  onClick={() => {
                    setIsEditForm();
                    setIsAddProfile();
                  }}
                  variant='outlined'
                >
                  Back
                </Button>
              </Box>
            )}

            <LoadingButton
              loading={isSubmitting}
              onClick={handleSubmit(onSave)}
              variant={"text"}
              color={"success"}
              aria-label={"save"}
              startIcon={!isEditForm && <IconLogin />}
              disabled={!!disabled}
            >
              {isEditForm ? "Save" : "Sign in"}
            </LoadingButton>
          </DialogActions>
        </Grid>
      </Grid>
    </Box>
  );
};
