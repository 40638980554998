import { FC } from "react";
// material-ui

// project imports
import { NavItemType } from "src/types";
import NavGroup from "./NavGroup";

// ==============================|| SIDEBAR MENU LIST ||============================== //

type MenuListProps = {
  menuItems: NavItemType[];
};

const MenuList: FC<MenuListProps> = ({ menuItems }) => {
  const navItems = menuItems.map(item => {
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} />;
      default:
        return <></>;
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
