import _ from "lodash";
import React, { FC, useEffect, useState } from "react";
import { isHostAvailable } from "src/App";
import Logo from "src/components/Logo";
import { BXApp } from "src/types/BXAppType";
import axiosServices from "src/utils/axios";
import { LoginForm } from "src/views/pages/BuildX/AppBuilder/forms/LoginForm";
import { useBXContext } from "../../../BXEngine/BXContext";
import UserProfiles from "../userProfiles/UserProfiles";

type PageAuthProps = {
  app: BXApp;
  children: React.ReactNode;
};

export const PageAuthGuard: FC<PageAuthProps> = ({ app, children }) => {
  const {
    isSwitchingMode,
    currentApp,
    appProfiles,
    loadingApps,
    getAuth,
    loginToApp,
    registerAppDevice,
    setIsSwitchingMode,
    setAppProfiles,
    currentProfileId,
    setCurrentProfileId,
  } = useBXContext();

  const [loading, setLoading] = useState(false);
  const [isEditForm, setIsEditForm] = useState<any>();
  const [isAddProfile, setIsAddProfile] = useState<any>();
  const [{ token, username, password }, setAuth] = useState({
    username: undefined,
    password: undefined,
    user: undefined,
    token: undefined,
  });
  const [error, setError] = useState<string | null>(null);
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    try {
      const auth = getAuth(app.id);
      const { token } = auth || {};
      const { username: cUsername, password: cPassword } = auth?.creds || {};

      if (!_.isEmpty(cUsername) && !_.isEmpty(cPassword) && !token) {
        setLoading(true);
        registerAppDevice?.(app.id)
          .then(async () => {
            await loginToApp?.(app.id, cUsername, cPassword).finally(() => {
              setLoading(false);
              setIsSwitchingMode(undefined as any);
            });
          })
          .final(() => {});
      }
      if (!_.isNil(auth)) setAuth(auth);
    } catch (e) {}
  }, [currentApp?.id]);

  useEffect(() => {
    setError(null);
    setAuth({ username: undefined, password: undefined, user: undefined, token: undefined });
    setIsEditForm(undefined);
    setIsAddProfile(false);
  }, [isSwitchingMode, currentApp]);

  const onSaveClick = async (values: any, cb: any = () => {}) => {
    setDisabled(true);
    await registerAppDevice?.(app.id);
    await loginToApp?.(
      app.id,
      values.email,
      values.password,
      () => {
        if (!isEditForm) {
          setDisabled(false);
          setIsSwitchingMode(false);
        }
        if (isEditForm) {
          setDisabled(true);
          axiosServices
            .patch(`application/${app?.id}/profile/${isEditForm?.id}`, {
              profileName: values?.profileName,
              creds: {
                username: values?.email,
                password: values?.password,
              },
            })
            .then(({ data }) => {
              setAppProfiles((previous: any) => {
                const prev = { ...previous };
                prev[app?.id as any] = prev[app?.id as any]?.map((profile: any) => (profile?.id == isEditForm?.id ? data : profile));
                return prev;
              });
            })
            .finally(() => {
              setDisabled(false);
              setIsEditForm(undefined);
            });
        }
        setError("");
        if (typeof cb == "function") cb?.();
      },
      isEditForm
    ).catch((loginError: any) => {
      setDisabled(false);
      setError(loginError.message);
      cb?.();
    });
  };

  const handleDeleteClick = (profile: any) => {
    axiosServices
      .delete(`application/${currentApp?.id}/profile/${profile?.id}`)
      .then(({ data }) => {
        setAppProfiles((previous = {} as any) => {
          const prev = { ...previous };

          localStorage.removeItem(currentApp?.id + `-${profile?.id}-accessToken`);
          localStorage.removeItem(currentApp?.id + `-${profile?.id}-user`);

          prev[currentApp?.id as any] = prev?.[currentApp?.id as any]?.filter((_profile: any) => _profile?.id != profile?.id);

          if (currentProfileId === profile?.id) {
            const newProfileId = prev?.[currentApp?.id as any]?.[0]?.id;
            if (newProfileId) {
              localStorage.setItem(currentApp?.id + "-profileId", newProfileId);
            }
            setCurrentProfileId(newProfileId);
          }

          return prev;
        });
      })
      .catch(() => {});
  };

  if (loadingApps) return <></>;

  const appProfile = appProfiles?.[currentApp?.id as any];

  if (
    currentApp?.appConfig?.auth?.authApi &&
    (_.isNil(getAuth(app.id)?.token) || _.isEmpty(getAuth(app.id)?.token) || isSwitchingMode) &&
    (localStorage.getItem("admin-login") ||
    isHostAvailable ||
    (currentApp?.appConfig?.withProfiles && currentApp?.appConfig?.isSingleSignOn)
      ? true
      : !currentApp?.appConfig?.isSingleSignOn)
  )
    return (
      <>
        {!loading && !(isEditForm || _.isEmpty(appProfile) || _.isNil(appProfile) || isAddProfile) && (
          <UserProfiles
            appId={app?.id}
            appProfiles={appProfiles}
            onSaveClick={onSaveClick}
            onEditClick={(data = {} as any) => {
              setAuth(prev => ({ ...prev, ...data?.creds }));
              setIsEditForm(data);
            }}
            onAddClick={() => {
              setIsAddProfile(true);
            }}
            onDeleteClick={handleDeleteClick}
          />
        )}
        {(isEditForm || _.isEmpty(appProfile) || _.isNil(appProfile) || isAddProfile) && (
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 40,
            }}
          >
            <Logo application={app} />
            {loading ? (
              <h2 style={{ marginLeft: 15 }}>Signing in...</h2>
            ) : (
              <LoginForm
                initial={{ username, password, profileName: isEditForm?.profileName }}
                id={app.id}
                error={error || ""}
                disabled={disabled}
                onSave={onSaveClick}
                onCancel={_.noop}
                isEditForm={isEditForm}
                setIsEditForm={setIsEditForm}
                isAddProfile={isAddProfile}
                setIsAddProfile={setIsAddProfile}
              />
            )}
          </div>
        )}
      </>
    );

  return <>{children}</>;
};
