import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// material-ui
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import makeStyles from "@mui/styles/makeStyles";

// project imports
import { MENU_OPEN, SET_MENU } from "src/stores/actions";
import { DefaultRootStateProps, LinkTarget, NavItemType } from "src/types";

// assets
import { BXIcon } from "src/components/BXUI/Icon";

// style constant
const useStyles = makeStyles((theme: Theme) => ({
  listIcon: {
    minWidth: "18px",
    marginTop: "auto",
    marginBottom: "auto",
  },
  menuIcon: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  listCustomIcon: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  listCustomIconSub: {
    width: "6px",
    height: "6px",
  },
  listCustomIconSubActive: {
    width: "8px",
    height: "8px",
  },
  listItem: {
    // marginBottom: "5px",
    alignItems: "center",
  },
  listItemNoBack: {
    marginBottom: "5px",
    backgroundColor: "transparent !important",
    paddingTop: "8px",
    paddingBottom: "8px",
    alignItems: "flex-start",
  },
  subMenuCaption: {
    // ...theme.typography.subMenuCaption
  },
}));

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

export interface NavItemProps {
  item: NavItemType;
  level: number;
}

const NavItem = ({ item, level }: NavItemProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const customization = useSelector((state: DefaultRootStateProps) => state.customization);
  const matchesSM = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const itemIcon = <BXIcon icon={item?.icon} height={24} width={24} />;

  let itemIconClass = item?.icon ? classes.listIcon : classes.menuIcon;
  /**
   * nav-dark doesnt exist on navType
   * navType can only be 'light' | 'dark'
   */

  let itemTarget: LinkTarget = "_self";
  if (item.target) {
    itemTarget = "_blank";
  }

  itemIconClass = customization.navType === "dark" ? [itemIconClass, classes.listCustomIcon].join(" ") : itemIconClass;

  let listItemProps: {
    component: React.ForwardRefExoticComponent<React.RefAttributes<HTMLAnchorElement>> | string;
    href?: string;
    target?: LinkTarget;
  } = { component: React.forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url!} target={itemTarget} />) };
  if (item?.external) {
    listItemProps = { component: "a", href: item.url, target: itemTarget };
  }

  const itemHandler = (id: string) => {
    dispatch({ type: MENU_OPEN, id });
    matchesSM && dispatch({ type: SET_MENU, opened: false });
  };

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      className={level > 1 ? classes.listItemNoBack : classes.listItem}
      sx={{ borderRadius: `${customization.borderRadius}px` }}
      selected={window.location.pathname === item.url}
      onClick={() => itemHandler(item.id!)}
      style={{ paddingLeft: `${level * 23}px`, paddingTop: 4, paddingBottom: 4 }}
    >
      <ListItemIcon className={itemIconClass} style={{ minWidth: level > 1 ? 18 : 32 }}>
        {itemIcon}
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            variant={customization.isOpen.findIndex((id: any) => id === item.id) > -1 ? "h5" : "body1"}
            color='textPrimary'
            fontSize={13}
          >
            {item.title}
          </Typography>
        }
        secondary={
          item.caption && (
            <Typography variant='caption' className={classes.subMenuCaption} display='block' gutterBottom>
              {item.caption}
            </Typography>
          )
        }
      />
      {item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  );
};

export default NavItem;
